const Data = [
    {
        name : "shivappa vatagal",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Good",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "mashak patel",
        rating : "5",
        commentdate : "09/10/2022",
        comment : "Good.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Mahadev Rathod",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Super Application Application.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Boya Raj",
        rating : "5",
        commentdate : "08/19/2022",
        comment : "Trusted Application.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Ajit Kamble",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "Badiya app hain.",
        ownereeplyer : {
            replyer : "Milan Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
    {
        name : "Laxman Sahu",
        rating : "5",
        commentdate : "08/19/2022",
        comment : "Fast withdraw application.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Chetan Chaudhari",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "No 1 application.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Adeppa Thigale",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "Hi I like your application sir.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Kumar Naik",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "Nice 👍👍👍👍👍 Super app.",
        ownereeplyer : {
            replyer : "Milan Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
    {
        name : "Chotu khan",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Super Application",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Devidas das",
        rating : "5",
        commentdate : "09/04/2022",
        comment : "Honest Trust",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Rajshekhar",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "Number 1 Application",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Anna Govinda",
        rating : "5",
        commentdate : "26/09/2020",
        comment : "Best Online matka app in our India best best best best matka app",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Rajendra Vishwakarma",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Very Good App",
        ownereeplyer : {
            replyer : "Milan Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
    {
        name : "Babu shaikh",
        rating : "5",
        commentdate : "08/01/2022",
        comment : "Super fast whithdraw system",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Anil Tadvi",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Super se upper application",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Shekhar Sawant",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "World best game app",
        ownereeplyer : {
            replyer : "Milan Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
    {
        name : "omar Singh",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "Very nice game sir",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Nayeem SK",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Very trusted app nice",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Ramu Reddy",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Super application Trusted Application",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Anand Thakur",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Very trusted application very good app",
        ownereeplyer : {
            replyer : "Milan Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
    {
        name : "Husen Basha",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Super application very very good application app game all game",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Rahul Ghunki",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Nio 1 apllication Best app very nice app Sir",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Shubham Akula",
        rating : "5",
        commentdate : "09/11/2022",
        comment : "Nice app !!!!!!!!! Very good app Good Design!!! Admin reply very good very fast result update",
        ownereeplyer : {
            replyer : "Milan Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
];

export default Data;