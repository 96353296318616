import { HomePage } from "./Comp/HomePage";
import { LoadingPage } from "./Comp/LoadingPage";

function App() {

  const data = "1";

  return (
    <div className="App">
      {
        data === "1" ? <HomePage /> : <LoadingPage />
      }
    </div>
  );
}

export default App;
